import { HttpClientModule } from "@angular/common/http";
import {  APP_INITIALIZER, NgModule } from "@angular/core"; 
import { BrowserModule, BrowserTransferStateModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ControlsModule } from "./components/controls/controls.module";
import { PrimeNgModule } from "./components/prime-ng/prime-ng.module";
import { SithecTranslateModule } from "./components/translate/sithec-translate.module";
import { ECommerceSithecCommonModule } from "./components/common-components/e-commerce-common.module";
import { sessionInitializerFactory, SessionInitializerService } from "./services/session-initializer.service";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { WebAuthenticationGuardService } from "./services/web-authentication-guard.service";
import { PurchaseAcceptedComponent } from './components/purchase-accepted/purchase-accepted.component';
import { ComprasComponent } from './components/compras/compras.component';
import { CertificadosComponent } from './components/certificados/certificados.component';
import { FilterPurchasePipe } from "./pipes/filter-purchase.pipe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FilterDetailPipe } from "./pipes/filter-detail.pipe";
import { VistaResiboComponent } from './components/vista-resibo/vista-resibo.component';
import { NgxPrintModule } from "ngx-print";
import { InformacionUsuarioComponent } from './components/informacion-usuario/informacion-usuario.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { RouterModule } from "@angular/router";
import { GarantiaComponent } from './components/garantia/garantia.component';
import { VerificarComponent } from './components/verificar/verificar.component';
import { ChecaCorreoComponent } from './components/checa-correo/checa-correo.component';
import { VerificaCelularComponent } from './components/verifica-celular/verifica-celular.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { Auth } from '@angular/fire/auth';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { ProductSelectedComponent } from "./components/product/product-selected/product-selected.component";
import { ProductDetailComponent } from "./components/product/product-detail/product-detail.component";
import { PayMethodComponent } from "./components/shopping-cart/components/pay-method/pay-method.component";
import { CommonComponentsModule } from "./components/common-components/common-components.module";
import { LandingModule } from "./components/landing/landing.module";
import { AuthenticationModule } from "./components/authentication/authentication.module";
import { ShoppingCartModule } from "./components/shopping-cart/shopping-cart.module";


@NgModule({
  declarations: [
    AppComponent, 
    DashboardComponent, 
    PurchaseAcceptedComponent, 
    ComprasComponent, 
    CertificadosComponent, 
    FilterPurchasePipe, 
    FilterDetailPipe, 
    VistaResiboComponent, 
    InformacionUsuarioComponent, 
    TerminosComponent, 
    AvisoComponent, 
    GarantiaComponent, 
    VerificarComponent,
    ChecaCorreoComponent,
    VerificaCelularComponent,
    ProductSelectedComponent,
    ProductDetailComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'testssr15' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    ECommerceSithecCommonModule,
    BrowserAnimationsModule,
    ControlsModule,
    HttpClientModule,
    PrimeNgModule,
    SithecTranslateModule,
    FormsModule,
    NgxPrintModule,
    ReactiveFormsModule,
    LandingModule,
    AuthenticationModule,
    ShoppingCartModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'top'
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
  
  providers: [ 
    WebAuthenticationGuardService,
  {
      provide: APP_INITIALIZER,
      useFactory: sessionInitializerFactory,
      deps: [SessionInitializerService],
      multi: true
    }, 
    { provide: "apiUrl", useValue: environment.common.apiUrl },
    { provide: "apiPath", useValue: "" },
    { provide: "sourceRequestor", useValue: environment.source },
    { provide: 'recaptchaKey', useValue: environment.common.recaptcha.siteKey },
    { provide: 'recaptchaUrl', useValue: environment.common.recaptcha.apiUrl },
    { provide: "dataDogApplication", useValue: environment.common.dataDogApplication },
    { provide: 'dataDogClient', useValue: environment.common.dataDogClient },
    { provide: 'datadogEnv', useValue: environment.common.datadogEnv },
    { provide: 'datadogService', useValue: environment.datadogService },
    { provide: 'datadogRecording', useValue: environment.datadogRecording },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
