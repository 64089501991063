<div class="recuadro">
  <div class="elementos-arriba">
    <div class="row  responsive-goback">
      <div class="go-back col-2" *ngIf="isMobileView">
        <img src="../../../../../../assets/images/boton-back.svg" alt="Go back" (click)="goBack()" id="imgCONTPAQi" >
      </div>
      <div class="col-10">
          <img [src]="imagen" id="imgProducto">
      </div>
    </div>
    
    
    <div class="textos">
      <p class="titulo">{{nombre}}</p>
      <p class="descripcion">{{descripcion}}</p>
    </div>
  </div>
  <div class="web-view">
    <div class="elementos-medio">
      <details class="selector" id="seleccion">
        <summary class="selection-text" #seleccion><span>{{transaccion}}</span></summary>
        <div class="display-selector">
          <div (click)="cambiarSeleccion('nuevo')">
            <div >
              <form-radio-check-button [id]="'2'" [seleccionActiva]="seleccionado" [isChecked]="isChecked[1]"></form-radio-check-button>
              <p>Licencia Nueva</p>
            </div>
          </div>
          <!-- <div routerLink="cambio" (click)="seleccion.click()" *ngIf="true"> -->
            <div  (click)="cambiarSeleccion('cambio')" *ngIf="mostrarCambio">
              <div >
              <form-radio-check-button [id]="'3'" [seleccionActiva]="seleccionado" [isChecked]="isChecked[2]"></form-radio-check-button>
              <p>Cambiar Características</p>
            </div>
          </div>
          <!-- <div routerLink="renovacion" (click)="seleccion.click()" *ngIf="true"> -->
            <div  (click)="cambiarSeleccion('renovacion')" *ngIf="mostrarRenovacion">
              <div>
              <form-radio-check-button [id]="'4'" [seleccionActiva]="seleccionado"  [isChecked]="isChecked[3]"></form-radio-check-button>
              <p>Renovación</p>
            </div>
          </div>
        </div>

      </details>
      
      
      
    </div>
    <div class="elementos-abajo">
      <p style="color:#FFF;">Consultar más información sobre este producto</p>
      
        <app-button
            class="full-width hover-button ajuste more"
            [label]="'CART.SEE' | translate"
            type="submit"
            (onClick)="conoceMas()"
          >
        </app-button>
    </div>
  </div>
  <div class="responsive-view">
    <div class="elementos-abajo">
      <p style="color:#FFF;">Consultar más información sobre este producto</p>
     
        <app-button
            class="full-width hover-button ajuste more"
            [label]="'CART.SEE' | translate"
            type="submit"
            (onClick)="conoceMas()"
          >
        </app-button>
    </div>
    <div class="elementos-medio">
      <details class="selector" id="seleccion2">
        <summary class="selection-text" #seleccion><span>{{transaccion}}</span></summary>
        <div class="display-selector">

          <div (click)="cambiarSeleccion('nuevo')">
            <div >
              <form-radio-check-button [id]="'2'" [seleccionActiva]="seleccionado" [isChecked]="isChecked[1]"></form-radio-check-button>
              <p>Licencia Nueva</p>
            </div>
          </div>
          <!-- <div routerLink="cambio" (click)="seleccion.click()" *ngIf="true"> -->
          <div  (click)="cambiarSeleccion('cambio')" *ngIf="mostrarCambio">
            <div >
            <form-radio-check-button [id]="'3'" [seleccionActiva]="seleccionado" [isChecked]="isChecked[2]"></form-radio-check-button>
            <p>Cambiar Características</p>
          </div>
          </div>
          <!-- <div routerLink="renovacion" (click)="seleccion.click()" *ngIf="true"> -->
            <div  (click)="cambiarSeleccion('renovacion')" *ngIf="mostrarRenovacion">
              <div>
              <form-radio-check-button [id]="'4'" [seleccionActiva]="seleccionado"  [isChecked]="isChecked[3]"></form-radio-check-button>
              <p>Renovación</p>
            </div>
          </div>
        </div>
      </details>
    </div>
  </div>
</div>
